#DropDown {
    appearance: none;
    -webkit-appearance: none;
    
    outline: none;
    border: none;
    background-color: var(--primary-color-light);
    color: var(--primary-color-dark);
    width: 100%;
    height: 30px;
    padding: 0px 15px;

    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;

    cursor: pointer;
};