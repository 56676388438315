#LoginScreen-Background {
  width: 50%;
  height: 100%;
  background-image: url("../images/background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
