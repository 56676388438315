#GrexManagerLogo-Container {
    position: absolute;
    height: 40px;
    width: 158px;
    top: 20px;
    left: 23px;
}

#GrexManagerLogo-Container svg {
    position: absolute;
    height: 100%;
    width: 100%;
}

#GrexManagerLogo-Container #GrexManagerLogo-Text {
    opacity: 1;
    -webkit-transition: opacity 200ms ease-out;
    -moz-transition: opacity 200ms ease-out;
    -o-transition: opacity 200ms ease-out;
    transition: opacity 200ms ease-out;
}