.fileInput {
    appearance: none;
    -webkit-appearance: none;
    
    background-color: var(--primary-color-light);
    color: var(--primary-color-dark);

    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;

    padding: 4px 5px;
    width: 300px;
    cursor: pointer;
};