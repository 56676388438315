#ButtonList-ButtonHolder {
  position: relative;
  margin: 0px 0px -8px 0px;
}

.ButtonList-Button {
  position: relative;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  color: white;
  background-color: var(--primary-color-dark);
  padding: 0px 15px;
  margin: 0px 8px 8px 0px;

  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;

  -webkit-transition: background-color 250ms ease-out;
  -moz-transition: background-color 250ms ease-out;
  -o-transition: background-color 250ms ease-out;
  transition: background-color 250ms ease-out;

  cursor: pointer;
}

.ButtonList-Button:hover {
  background-color: var(--primary-color-dark-hover);
}

.ButtonList-Button.disabled {
  background-color: var(--text-color);
  cursor: default;
}

.ButtonList-Button.warning {
  background-color: var(--error-text-color);
}
