#MessagePopup {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  z-index: 2;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#MessagePopup-Section {
  background-color: white;

  border: 2px solid var(--line-color);
  margin: 20px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  max-width: 80%;
}

#MessagePopup-Section #MessagePopup-Title {
  text-align: center;
  margin: 10px 10px 0px 10px;
  max-width: 600px;
  max-width: 600px;
  font-weight: bold;
  color: var(--primary-color-dark-hover);
  font-size: 20px;
}

#MessagePopup-Section #MessagePopup-Text {
  text-align: center;
  margin: 10px 10px 0px 10px;
  max-width: 500px;
}

#MessagePopup-Section #ProgressMessagePopup-Container {
  display: flex;
  flex-direction: row;
  align-items: center; /* Vertically centers the items */
  justify-content: center;
  gap: 1px;
}

#MessagePopup-Section #MessagePopup-Buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#MessagePopup-Section #MessagePopup-Buttons #ButtonList-ButtonHolder {
  margin: 10px 0px 0px 10px;
}

#MessagePopup-Section #MessagePopup-ProgressBar {
  text-align: center;
  margin: 10px 10px 0px 10px;
  max-width: 500px;
}

#MessagePopup-Section #TermsOfUse {
  margin: 20px;
}
