.SamlClientScreen-Table th {
  min-width: 170px;
  padding: 3px 0px;
  color: var(--text-color);
}

.SamlClientScreen-Table td {
  min-width: 170px;
  padding: 3px 0px;
  color: var(--primary-color-dark);
  white-space: nowrap;
}

#SamlClientScreen .Button {
  min-width: 200px;
  margin-top: 0px;
}

#SamlClientScreen .TextInput-Background {
  width: 300px;
}
