#EditSubregionScreen {
  height: 100%;

  display: flex;
  flex-direction: column;
}

.EditSubregionScreen-ErrorMessage {
  margin: -2px 0px 10px 4px;
  color: var(--error-text-color);
}

.EditSubregionScreen-Table th {
  min-width: 170px;
  padding: 3px 0px;
  color: var(--text-color);
}

.EditSubregionScreen-Table td {
  min-width: 170px;
  padding: 3px 0px;
  color: var(--primary-color-dark);
  white-space: nowrap;
}

#EditSubregionScreen .Button {
  min-width: 200px;
  margin-top: 0px;
}

#EditSubregionScreen .TextInput-Background {
  width: 300px;
}
