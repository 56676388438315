#BreadCrumbs-Container {
    position: relative;
    user-select: none;
    margin-bottom: 4px; /* + 8px from the buttons*/
}

.BreadCrumbs-Button {
    position: relative;
    height: 30px;
    line-height: 30px;
    display: inline-block;
    vertical-align: top;
    color: var(--text-color);
    background-color: var(--primary-color-ultra-light);
    padding: 0px 10px;
    margin: 0px 0px 8px 0px;

    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;

    -webkit-transition: all 250ms ease-out;
    -moz-transition: all 250ms ease-out;
    -o-transition: all 250ms ease-out;
    transition: all 250ms ease-out;

    cursor: pointer;
}

.BreadCrumbs-Button:hover {
    color: var(--primary-color-dark);
}


.BreadCrumbs-Arrow {
    position: relative;
    height: 30px;
    line-height: 30px;
    display: inline-block;
    vertical-align: top;
    color: var(--text-color);
    margin: 0px 8px 8px 8px;
}


#BreadCrumbs-BackButton {
    width: 30px;
    padding: 0px;
    margin: 0px 8px 8px 0px;
    display: inline-flex;
    vertical-align: top;
    justify-content: center;
    align-items: center;
}

#BreadCrumbs-BackButton svg {
    height: 20px;
    width: 20px;
}

#BreadCrumbs-BackButton:hover svg {
    stroke: var(--primary-color-dark);
}

.BreadCrumbs-CurrentPageButton {
    color: var(--primary-color-dark);
}