.EditModelTemplateScreen-ErrorMessage {
  margin: -2px 0px 10px 4px;
  color: var(--error-text-color);
}

.EditModelTemplateScreen-Table th {
  /* min-width: 170px; */
  padding: 3px 0px;
  color: var(--text-color);
}

.EditModelTemplateScreen-Table td {
  min-width: 170px;
  padding: 3px 0px;
  color: var(--primary-color-dark);
  white-space: nowrap;
}

.EditModelTemplateScreen-Table td.EditModelTemplateScreen-ActiveButton {
  min-width: 50px;
  /* width: 50px; */
  cursor: pointer;
  padding: 3px 17px;
}

#EditModelTemplateScreen .Button {
  min-width: 200px;
  margin-top: 0px;
}

#EditModelTemplateScreen .TextInput-Background {
  width: 300px;
}
