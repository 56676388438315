#LoadingOverlay {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;

    z-index: 2;
    
    background-color: rgba(255,255,255,0.9);
}