.CheckBox-CheckBox {
    position: relative;
    cursor: pointer;
    height: 18px;
    width: 18px;
    
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;

    border: 2px solid var(--primary-color-dark-hover);
}

.CheckBox-CheckBox:hover {
    background-color: var(--primary-color-dark);
}

.CheckBox-Checked {
    background-color: var(--primary-color-dark-hover);
}

.CheckBox-CheckMark {
    position: absolute;
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}