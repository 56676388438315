#DocumentationListScreen {
  height: 100%;

  display: flex;
  flex-direction: column;
}

#DocumentationListScreen-ScrollView {
  position: relative;
  flex-grow: 1;
  width: 100%;

  border: 2px solid var(--line-color);

  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;

  overflow: auto;

  margin-bottom: 12px;
}

#DocumentationListScreen-ScrollHolder {
  position: absolute;
  top: 0px;
  left: 0px;
}

#DocumentationListScreen-Table {
  margin: 0px 15px;
}

#DocumentationListScreen-Table th {
  min-width: 150px;
  padding: 10px 10px;
  color: var(--text-color);
}

#DocumentationListScreen-Table tbody tr {
  cursor: pointer;
}

#DocumentationListScreen-Table tbody tr:hover {
  background-color: var(--primary-color-light);
}

#DocumentationListScreen-Table tbody tr td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

#DocumentationListScreen-Table tbody tr td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

#DocumentationListScreen-Table td {
  min-width: 150px;
  padding: 5px 10px;
  color: var(--primary-color-dark);
  white-space: nowrap;
}

#DocumentationListScreen-GrexVersion {
  position: absolute;
  bottom: 24px;
  right: 24px;
  color: #7989a1;
  font-size: 14px;
}
