#LoginScreen-Container {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: var(--primary-color-ultra-light);

  user-select: none;
  display: flex;
}

#LoginScreen-Section {
  min-width: 400px;
  width: 50%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-right: 2px solid var(--line-color);
}

#LoginScreen-LoginHolder {
  width: 320px;
  margin-bottom: 120px;
}

#LoginScreen-LoginHolder .label {
  margin-top: 10px;
}

#LoginScreen-LoginHolder .Button {
  width: 100%;
  margin-top: 15px;
}
