.Button {
  margin-top: 10px;
  position: relative;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  color: white;
  background-color: var(--primary-color-dark);
  padding: 0px 15px;
  text-align: center;

  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;

  -webkit-transition: background-color 250ms ease-out;
  -moz-transition: background-color 250ms ease-out;
  -o-transition: background-color 250ms ease-out;
  transition: background-color 250ms ease-out;

  cursor: pointer;
  overflow: hidden;
}

.Button:hover {
  background-color: var(--primary-color-dark-hover);
}

.Button.disabled {
  background-color: var(--text-color);
  cursor: default;
}
