.TextInput-Background {
  position: relative;
  height: 30px;
  line-height: 30px;

  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;

  padding: 0px 15px;
  background-color: var(--primary-color-light);
  color: rgb(0, 0, 0);
  font-size: 17px;
  /* font-family: OpenSans; */
  overflow: hidden;
}

.TextInput-Background.TextInput-BackgroundFocused {
  background-color: var(--primary-color-ultra-light);
}

.TextInput-Input {
  position: absolute;
  appearance: none;
  -webkit-appearance: none;
  height: 100%;
  width: calc(100% - 30px); /* 30px = 2 * 15px(padding) */
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  outline: none;
  border: none;
  color: var(--primary-color-dark);
  font-size: 17px;
  /* font-family: OpenSans; */
}

.TextInput-Input::placeholder {
  color: var(--text-color);
  opacity: 1; /* Firefox */
}

.TextInput-Input::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: var(--text-color);
}
