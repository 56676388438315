.CreateClientScreen-Table th {
  min-width: 170px;
  padding: 3px 0px;
  color: var(--text-color);
}

.CreateClientScreen-Table td {
  min-width: 170px;
  padding: 3px 0px;
  color: var(--primary-color-dark);
  white-space: nowrap;
}

#CreateClientScreen .Button {
  min-width: 200px;
  margin-top: 0px;
}

#CreateClientScreen .TextInput-Background {
  width: 300px;
}
