.AddReportTemplateScreen-ErrorMessage {
  margin: -2px 0px 10px 4px;
  color: var(--error-text-color);
}

.AddReportTemplateScreen-Table th {
  min-width: 170px;
  padding: 3px 0px;
  color: var(--text-color);
}

.AddReportTemplateScreen-Table td {
  min-width: 170px;
  padding: 3px 0px;
  color: var(--primary-color-dark);
  white-space: nowrap;
}

#AddReportTemplateScreen .Button {
  min-width: 200px;
  margin-top: 0px;
}

#AddReportTemplateScreen .TextInput-Background {
  width: 300px;
}
