.InfoMessage {
  display: flex;
  align-items: center;
  padding: 10px 15px 0px 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #14366e;
  background-color: #e9f4fa;
  border-color: #adc4eb;
  max-width: 500px;
}

.ErrorMessage {
  display: flex;
  align-items: center;
  padding: 10px 15px 0px 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;

  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  max-width: 500px;
}
