#MenuBar-Holder {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;

  z-index: 1;

  user-select: none;
}

#MenuBar-Main {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 72px; /* MenuBar width 70px + 2px for the border */
  overflow: hidden;
  background-color: var(--primary-color-ultra-light);

  -webkit-transition: width 250ms ease-out 50ms;
  -moz-transition: width 250ms ease-out 50ms;
  -o-transition: width 250ms ease-out 50ms;
  transition: width 250ms ease-out 50ms;

  border-right: 2px solid var(--line-color);
}

#MenuBar-Holder:hover #MenuBar-Main {
  width: 204px; /* The width of the logo + 2 * the margin */
}

/* Logo */
#MenuBar-Main #GrexManagerLogo-Container #GrexManagerLogo-Text {
  -webkit-transition: opacity 150ms ease-out 50ms;
  -moz-transition: opacity 150ms ease-out 50ms;
  -o-transition: opacity 150ms ease-out 50ms;
  transition: opacity 150ms ease-out 50ms;

  opacity: 0;
}

#MenuBar-Holder:hover
  #MenuBar-Main
  #GrexManagerLogo-Container
  #GrexManagerLogo-Text {
  opacity: 1;
}

/* Menu */
#MenuBar-MenuScrollHolder {
  position: absolute;
  top: 70px; /* Add some room for the logo */
  left: 0px;
  height: calc(
    100% - 70px - 70px
  ); /* Add room for the logo and the user info */
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#MenuBar-MenuScrollHolder::-webkit-scrollbar {
  display: none;
}

.MenuBar-MenuFlexHolder {
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 290px; /* Height of 6 NavItems and 5 margins = (6 * 40px) + (5 * 10px) */
  height: 100%;
  width: 100%;
}

.MenuBar-NavItem {
  position: relative;
  color: var(--primary-color-dark);
  height: 40px;
  line-height: 40px;
  width: 40px;
  padding: 0px;
  display: block;
  overflow: hidden;

  -webkit-transition: width 250ms ease-out 50ms;
  -moz-transition: width 250ms ease-out 50ms;
  -o-transition: width 250ms ease-out 50ms;
  transition: width 250ms ease-out 50ms;

  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;

  cursor: pointer;
}

.MenuBar-NavItem:hover {
  background-color: var(--primary-color-light);
}

.MenuBar-NavItemSelected {
  background-color: var(--primary-color-light);
}

.MenuBar-NavItemMargin {
  margin-bottom: 10px; /* The last menu item should not have a margin */
}

#MenuBar-Holder:hover #MenuBar-Main .MenuBar-NavItem {
  width: 172px; /* The width of #MenuBar-Holder:hover #MenuBar-Main - (72px - 40px) */
}

.MenuBar-NavIcon {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MenuBar-NavIcon svg {
  height: 20px;
  width: 20px;
}

.MenuBar-NavText {
  position: absolute;
  top: 0px;
  left: 40px;
  width: calc(100% - 40px);
  opacity: 0;
  -webkit-transition: opacity 150ms ease-out 50ms;
  -moz-transition: opacity 150ms ease-out 50ms;
  -o-transition: opacity 150ms ease-out 50ms;
  transition: opacity 150ms ease-out 50ms;
}

#MenuBar-Holder:hover #MenuBar-Main .MenuBar-NavText {
  opacity: 1;
}

/* User */
#MenuBar-UserItemFlexHolder {
  position: absolute;
  bottom: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 110px;
  width: 100%;
}

/* Submenu */
#MenuBar-SubMenuBar {
  position: fixed;
  top: 0px;
  left: 72px; /* MenuBar-Main width */
  height: 100%;
  width: 0px;
  opacity: 0;
  background-color: var(--primary-color-ultra-light);

  -webkit-transition: left 250ms ease-out 50ms, opacity 250ms ease-out 50ms,
    width 250ms ease-out 50ms;
  -moz-transition: left 250ms ease-out 50ms, opacity 250ms ease-out 50ms,
    width 250ms ease-out 50ms;
  -o-transition: left 250ms ease-out 50ms, opacity 250ms ease-out 50ms,
    width 250ms ease-out 50ms;
  transition: left 250ms ease-out 50ms, opacity 250ms ease-out 50ms,
    width 250ms ease-out 50ms;

  border-right: 2px solid var(--line-color);

  z-index: 1;

  user-select: none;

  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#MenuBar-SubMenuBar::-webkit-scrollbar {
  display: none;
}

#MenuBar-Holder:hover #MenuBar-SubMenuBar {
  left: 204px; /* MenuBar-Main:hover width */
}

#MenuBar-SubMenuBar.MenuBar-SubMenuBarVisible {
  opacity: 1;
  width: 237px;
}

#MenuBar-SubMenuBar .MenuBar-NavItem {
  width: 205px; /* The width of #MenuBar-SubMenuBar.MenuBar-SubMenuBarVisible - (72px - 40px) */
}

#MenuBar-SubMenuBar .MenuBar-NavText {
  opacity: 1;
}
